@import "src/assets/css/mixins";
.profileContent {
  display: flex;
  flex-direction: column;
  width: 760px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  overflow: hidden;
  @include tablet {
    width: 100%;
    background: none;
  }
  @include mobile {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
}

.profileStatistic {
  padding: 0 32px 32px;
  @include mobile {
    padding: 12px;
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
  }
  & > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2F353B;
    text-align: left;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    .infoIcon{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & > p,& .profileStatisticCount {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    height: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #002846;
    @include mobile {
      font-size: 13px;
      margin-top: 12px;
    }
    strong {
      font-weight: 700;
      font-size: 16px;
    }
  }
}
.profileStatisticList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  @include mobile {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  & > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 134px;
    padding: 23px 0;
    background: #EAF9FE;
    border-radius: 8px;
    @include mobile {
      height: 92px;
      padding: 6px 0;
    }
    & > img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-bottom: 8px;
      @include mobile {
        margin-bottom: 4px;
      }
    }
    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #2f353c;
      margin-bottom: 4px;
      @include mobile {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 0;
      }
    }
    & > strong {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #2F353C;
      @include mobile {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
}

.profileStatisticCount{
  span{
    display: flex;
    align-items: center;
    gap: 8px;
    .infoIcon{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

